import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import WidgetBox, {WidgetTitle} from '../../../components/shared/widget-box'
import Social from '../../../components/socials/layout-two'
import {AuthorWrap, AuthorImg, AuthorName, AuthorTagline} from './author.stc'

const Author = ({content, widgetStyle, socialCSS}) => {
    const author = content || {};
    const {name, image, tagline, social} = author;

    return (
        <WidgetBox {...widgetStyle}>
            <WidgetTitle>About Me</WidgetTitle>
            <AuthorWrap>
                {image && (
                    <Link to={'/about'}>
                        <AuthorImg>
                            <Img fixed={image.childImageSharp.fixed} alt={name || 'Author Name'}/>
                        </AuthorImg>
                    </Link>
                )}
                {name && <Link to={'/about'}><AuthorName>{name}</AuthorName></Link>}
                {tagline && <AuthorTagline>{tagline}</AuthorTagline>}
                {social && <Social {...socialCSS} social={social}/>}
            </AuthorWrap>
        </WidgetBox>
    )
}

Author.defaultProps = {
    widgetStyle: {
        textAlign: 'center'
    },
    socialCSS: {
        color: 'shark',
        borderColor: 'shark'
    }
}

export default Author

